<template>
  <ContentWrapper v-if="!!hero" class="hero-wrapper" fluid>
    <HeroHint
      v-if="smAndDown && !notNoonYet"
      :hint-label="hero.hint_label"
      :hint-text="hero.hint_text"
      :hint-mobile-text="hero.hint_mobile_text" />
    <HeroCounter v-if="smAndDown && notNoonYet" @now-is-noon="noonHandler" />
    <v-container class="pa-0">
      <v-row class="inside" dense>
        <v-col cols="12" md="7" lg="8" class="bg__gradient_top">
          <h1 class="text-center mt-8 mb-6 title">
            {{ hero.title }}
          </h1>
          <div class="claims">
            <div v-for="claim in hero.claims" :key="claim" class="font-weight-bold py-2">
              <v-icon class="text-primary">custom:check</v-icon>
              <span class="pl-4 claim">{{ claim }}</span>
            </div>
          </div>
          <div class="mt-8">
            <v-img
              :max-height="smAndUp ? '420px' : '210px'"
              :alt="fraction?.dc_image_alt_text || ''"
              :src="`https://epd.directus.app/assets/${fraction?.displayed_container_image}?format=webp`"
              :srcset="`https://epd.directus.app/assets/${fraction?.displayed_container_image}?format=webp 1x, https://epd.directus.app/assets/${fraction?.displayed_container_image}?format=webp 2x`" />
          </div>
          <HeroHint
            v-if="!smAndDown && !notNoonYet"
            :hint-label="hero.hint_label"
            :hint-text="hero.hint_text"
            :hint-mobile-text="hero.hint_mobile_text" />

          <v-row
            v-if="!smAndDown && notNoonYet"
            align="start"
            align-xl="end"
            justify="end"
            class="counter-wrapper mb-8 pb-xl-16 pt-0 mt-n16 pr-16"
            no-gutters>
            <v-col cols="auto" class="pt-0 mt-n16">
              <HeroCounter @now-is-noon="noonHandler" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" lg="4" class="bg__plain px-3 pr-md-0">
          <HeroPostcode
            v-if="!showDetail && !notFound"
            :title="hero.right_side_title"
            :text="hero.right_side_text"
            @zip-code-entered="confirmZipcode" />
          <HeroContainerDetail v-if="showDetail && !notFound && currentOffers?.length > 0" />
          <HeroOfferNotFound
            v-if="notFound && currentOffers.length < 1"
            @zip-code-entered="confirmZipcode" />
        </v-col>
      </v-row>
    </v-container>
  </ContentWrapper>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { City, Container, ContainerOffer, Hero } from '~/types/types'
const { smAndDown, smAndUp } = useDisplay()
const fractionStore = useFractionStore()
const containerStore = useContainerStore()
const offerStore = useOfferStore()
const { currentOffers, currentCity } = storeToRefs(offerStore)
containerStore.getContainerData()
fractionStore.getFractionData()
const { fractions } = storeToRefs(fractionStore)
const { containers } = storeToRefs(containerStore)
const { createNewGermanDate } = useDateUtils()
const { findOffer } = useApiWrapper()
const props = defineProps<{
  hero: Hero | null
}>()
const { hero } = props
const notFound = ref(false)
offerStore.setCurrentFractionId(hero?.fraction as number)
// don't delete in case we get a new desing for showing something else

const notNoonYet = ref(createNewGermanDate().getHours() < 12)

const noonHandler = () => {
  notNoonYet.value = false
}

const fraction = computed(() => fractions.value?.find(fraction => fraction.id === hero?.fraction))

//temporary solution to check zipcode available offers
const showDetail = ref(
  !currentCity.value?.zip || currentCity.value?.zip === null || currentCity.value?.zip === ''
    ? false
    : true,
)

const confirmZipcode = (zipCodeInput: string) => {
  const givenZipcode =
    zipCodeInput === '' || zipCodeInput === null ? currentCity.value?.zip : zipCodeInput
  if (!givenZipcode || givenZipcode === '' || givenZipcode === null) {
    return
  }
  checkForOffers(fraction.value?.empto_id as number, givenZipcode)
}
const checkForOffers = (emptoFractionId: number, zipCode: string) => {
  findOffer(emptoFractionId, zipCode)
    .then((response: any) => {
      if (!response || !response.length || response?.length < 1) {
        offerStore.setCurrentOffers([])
        notFound.value = true
        return
      }
      const offers = [] as ContainerOffer[]
      for (const offer of response) {
        const foundContainer = containers.value?.find(
          container => container.empto_id === offer.containerId,
        ) as Container
        const offerGrossPriceInCent = useMoneyHelper().convertToCentNumber(Number(offer.grossPrice))
        const offerNetPriceInCent = useMoneyHelper().calculatePriceWithouVatInCent(
          offerGrossPriceInCent,
          Number(offer.vat),
        )

        const finalOffer: ContainerOffer = {
          directusContainerId: foundContainer.id,
          emptoContainerId: offer.containerId,
          directusFractionId: fraction.value!.id,
          emptoFractionId: emptoFractionId,
          offerId: offer.offerId,
          vatPercentage: offer.vat,
          unitPrice: {
            netPrice: offerNetPriceInCent,
            grossPrice: offerGrossPriceInCent,
            volumePrice: useMoneyHelper().convertToCentNumber(Number(offer.volumePrice)),
            vat: offerGrossPriceInCent - offerNetPriceInCent,
          },
          productHeight: offer.productHeight,
          productLength: offer.productLength,
          productWidth: offer.productWidth,
          productSize: offer.productSize,
          productSpace: offer.productSpace,
          description: foundContainer.description,
          size: foundContainer.size,
          space: foundContainer.space,
          dimension: foundContainer.dimension,
          image: foundContainer.image,
        }
        offers.push(finalOffer)
      }
      offerStore.setCurrentOffers(offers)
      if (offers.length < 1) {
        notFound.value = true
        return
      }
      notFound.value = false
      showDetail.value = true
    })
    .catch(() => {
      offerStore.setCurrentOffers([])
      notFound.value = true
    })
}
watch(currentOffers, newValue => {
  if (!newValue.length || newValue.length < 1) {
    notFound.value = true
    showDetail.value = false
  }
})

onBeforeMount(() => {
  const previousSelectedCity = currentCity.value as City
  if (previousSelectedCity?.zip) {
    confirmZipcode(previousSelectedCity.zip)
  }
})
</script>

<style lang="scss" scoped>
.hero-wrapper {
  background: linear-gradient(
    60deg,
    rgb(var(--v-theme-white)) 12%,
    rgb(var(--v-theme-surface-gradient)) 30%,
    rgb(var(--v-theme-white)) 60%
  );
}
.title {
  hyphens: auto;
  word-wrap: break-word;
}
.inside {
  min-height: 850px;
  height: fit-content;
}
.claims {
  margin: 0 auto;
  width: fit-content;
}
.front-icon {
  z-index: 100;
}
.hero-input :deep(input) {
  text-align: center !important;
}
@media (max-width: 959px) {
  .inside {
    height: fit-content;
  }
}
@media (min-width: 960px) and (max-width: 1250px) {
  .claims,
  .counter-wrapper {
    transform: scale(0.9);
  }
}
@media (min-width: 2560px) {
  .inside {
    max-height: 1180px;
  }
}

@media (max-width: 449px) {
  h1 {
    font-size: 38px;
  }
}
@media (max-width: 380px) {
  h1 {
    font-size: 34px;
  }
}
</style>
